@tailwind base;
@tailwind components;
@tailwind utilities;

.c-card:hover{
    background: rgb(45,232,134);
    background: linear-gradient(0deg, rgba(45,232,134,0.6881346288515406) 0%, rgba(93,163,250,1) 100%);
    color: white;
}


